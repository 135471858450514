<template>
  <v-container fluid>
    <!-- <v-col cols="12" sm="3" order-sm="1" order="2">
				<v-card class="pa-2">
					<v-img :src="require('@/assets/imagen_formulario.svg')"></v-img>
					<p class="ma-2">sdkfng jsdfhg jdfhgjkfdhg jkfh gjkfh gjh </p>
				</v-card>
		</v-col>-->
    <v-subheader>Datos Personales</v-subheader>
    <v-card class="pa-4">
      <v-form ref="formulario">
        <v-row no-gutters>
          <v-col md="6" cols="12">
            <!-- <aux-input title="DNI">
							<v-text-field
								filled
								dense
								hide-details="auto"
								placeholder="DNI"
								v-model="datos.dni"
							/>
						</aux-input>
						<v-divider />
						<aux-input title="Nombre">
							<v-text-field filled dense hide-details="auto" placeholder="Nombre" v-model="datos.nombre" />
						</aux-input>
						<v-divider />
						<aux-input title="Primer Apellido">
							<v-text-field
								filled
								dense
								hide-details="auto"
								placeholder="Primer Apellido"
								v-model="datos.apellido1"
							/>
						</aux-input>
						<v-divider />
						<aux-input title="Segundo Apellido">
							<v-text-field
								filled
								dense
								hide-details="auto"
								placeholder="Segundo Apellido"
								v-model="datos.apellido2"
							/>
						</aux-input> -->
            <aux-input title="Empresa">
              <v-text-field
                filled
                dense
                hide-details="auto"
                placeholder="Empresa"
                v-model="datos.empresa"
              />
            </aux-input>
            <v-divider />
            <aux-input title="Persona Contacto">
              <v-text-field
                filled
                dense
                hide-details="auto"
                placeholder="Persona Contacto"
                v-model="datos.personaContacto"
              />
            </aux-input>
            <v-divider />
            <aux-input title="Correo electrónico">
              <v-text-field
                filled
                dense
                hide-details="auto"
                placeholder="Correo electrónico"
                :rules="[!datos.telefono ? rules.req : true]"
                v-model="datos.email"
              />
            </aux-input>
            <v-divider />
            <aux-input title="Teléfono">
              <v-text-field
                filled
                dense
                hide-details="auto"
                placeholder="Teléfono"
                :rules="[!datos.email ? rules.req : true]"
                v-model="datos.telefono"
              />
            </aux-input>
            <v-divider />
						<aux-input title="">
							<div class="d-flex" style="gap: 10px">
                <v-checkbox
                  v-for="o in opciones"
                  :key="o.id"
                  v-model="datos.opciones"
                  :label="o.nombre"
                  :value="o.nombre"
                ></v-checkbox>
              </div>
						</aux-input>
          </v-col>
          <v-col md="6" cols="12">
            <aux-input title="Ciudad">
              <v-text-field
                filled
                dense
                hide-details="auto"
                placeholder="Ciudad"
                v-model="datos.ciudad"
              />
            </aux-input>
            <v-divider />
            <aux-input title="Código Postal">
              <v-text-field
                filled
                dense
                hide-details="auto"
                placeholder="Código Postal"
                v-model="datos.codPostal"
              />
            </aux-input>
            <v-divider />
            <aux-input title="Provincia">
              <v-text-field
                filled
                dense
                hide-details="auto"
                placeholder="Provincia"
                v-model="datos.provincia"
              />
            </aux-input>
            <v-divider />
            <aux-input title="Comentario">
              <v-textarea
                filled
                dense
                hide-details="auto"
                placeholder="Comentario"
                no-resize
                style="height: 186px"
                v-model="datos.comentario"
              />
            </aux-input>
            <v-divider />
          </v-col>
          <v-col cols="12">
            <aux-input>
              <template v-slot:title>
                <h5>Imágen</h5>
                <div class="d-flex mt-3" v-if="fotoSubida !== true">
                  <img style="max-height: 200px" contain :src="fotoSubida" />
                  <v-btn @click="datos.imagen = null" color="error" icon>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </template>
              <!-- <input type="file" accept="image/*" capture="camera" /> -->
              <v-file-input
                filled
                dense
                hide-details="auto"
                placeholder="Imágen"
                accept="image/*"
                v-model="datos.imagen"
                multiple
              />
            </aux-input>
          </v-col>
        </v-row>
      </v-form>
    </v-card>

    <v-dialog v-model="ok" max-width="500" >
      <v-card class="pa-4 d-flex align-center" style="gap: 10px;" >
        <h3>Los datos se han enviado correctamente</h3>
        <v-icon x-large color="success">mdi-check-all</v-icon>
      </v-card>
    </v-dialog>

    <div class="d-flex flex-wrap mt-3">
      <v-spacer />
      <v-btn @click.stop="enviar" color="primary" class="ma-1" large>
        <v-icon left>mdi-content-save</v-icon>Enviar
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { req, phone, email, ValidateSpanishID } from "@/utils/validations.js";
import axios from "axios";

export default {
  components: {
    AuxInput: () => import("@/components/AuxInput.vue"),
  },
  computed: {
    fotoSubida() {
      return !this.datos.imagen || URL.createObjectURL(this.datos.imagen);
    },
  },
  data() {
    return {
      rules: { req, phone, email, dni: ValidateSpanishID },
      datos: {
				opciones: []
			},
      opciones: [],
      ok: false
    };
  },
  methods: {
    async getOpciones() {
      const { data } = await axios({
        url: `${process.env.VUE_APP_OUR_API_URL}/posiblesColaboradoresFeriaOpciones.php`,
        method: "GET",
      });

      this.opciones = data;
    },
    async enviar() {
      if (!this.$refs.formulario.validate()) return;

      // for (let key in this.datos) {
      // 	if (key != 'imagen');
      // 	formData.append(key, this.datos[key]);
      // }

      // const getImagen = (img) => {
      // 	return new File([img], `IMAGEN_${this.datos.dni}.png`, {
      // 		type: 'image/png'
      // 	})
      // }

      // formData.append('origen', this.$store.getters.getToken || 'QR');
      // formData.append('imagen', getImagen(this.datos.imagen));

      try {
        const { data: idPosibleColaborador } = await axios({
          url: `${process.env.VUE_APP_OUR_API_URL}/posiblesColaboradoresFeria.php`,
          method: "POST",
          data: {
            datos: this.datos,
            origen: this.$store.getters.getToken || "0",
          },
        });

        if (!this.datos.imagen || this.datos.imagen.length == 0) {
          this.$root.$emit("snack", "Los datos se han enviado correctamente");
          this.datos = {
            opciones: []
          };
          this.ok = true;
          return;
        }

        const formData = new FormData();

        const getImagen = (img) => {
          return new File(
            [img],
            `IMAGEN_${idPosibleColaborador}_${Math.floor(
              Math.random() * 100
            )}.png`,
            {
              type: "image/png",
            }
          );
        };
        for (const img of this.datos.imagen) {
          formData.append("imagen[]", getImagen(img));
        }
        formData.append("idPosibleColaborador", idPosibleColaborador);

        await axios({
          url: `${process.env.VUE_APP_OUR_API_URL}/posiblesColaboradoresFeria.php`,
          method: "POST",
          headers: {
      'Content-Type': 'multipart/form-data'
    },
          data: formData,
        });

        // this.$router.push({ name: "Listado" });
        this.$root.$emit("snack", "Los datos se han enviado correctamente");
        this.ok = true;
      } catch (err) {
        console.error(err);
        this.$root.$emit("snack", "Ha ocurrido un error inesperado");
      }
    },
  },
	mounted() {
		this.getOpciones()
	}
};
</script>
